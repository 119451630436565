import React from "react"
import Layout from "../components/root/Layout"
import Seo from "../components/root/Seo"

export default function Privacy({data}) {
  return (
    <Layout>
      <Seo title="Privacy" />
      <div className="container-lg py-8 md:py-16 lg:py-24 text-gray-800">
        <h2 className="text-5xl md:text-6xl leading-tight font-bold text-gray-800">
          Privacy Policy
        </h2>
        <div className="article" dangerouslySetInnerHTML={{__html:data.prismicPrivacyPolicy.data.privacy_policy.html}}/>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
{
  prismicPrivacyPolicy {
    data {
      privacy_policy {
        html
      }
    }
  }
}
`
